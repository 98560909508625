<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-4 mx-auto">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("forgotPassword.passwordRetrieval")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        :rules="rules.emailRules"
                        :label="$t('common.email')"
                        @keypress.enter="onForgotPassword($event)"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center align-center">
              <v-btn
                :loading="isLoading"
                align-center
                justify-center
                color="primary"
                @click="onForgotPassword($event)"
                >{{ $t("confirmModal.btn.confirm") }}</v-btn
              >
            </v-card-actions>
            <p class="py-2 pointer" @click="onGoToLogin">
              {{ $t("forgotPassword.back") }}
            </p>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
    />
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import functionUtils from "Utils/functionUtils";
import stringUtils from "Utils/stringUtils";
import { mapActions } from "vuex";
import LoginFromType from "Enum/loginFromType.js";
import ConfirmModal from "Components/ConfirmModal";
export default {
  components: {
    IntroNavigation,
    ConfirmModal
  },
  data() {
    return {
      valid: false,
      show1: false,
      isLoading: false,
      email: "",
      rules: {
        required: (value) => !!value || this.$t("login.thisFieldIsRequired"),
        emailRules: [
          (v) => !!v || this.$t("login.thisFieldIsRequired"),
          (v) => /.+@.+\..+/.test(v) || this.$t("login.emailMustBeValid"),
        ],
      },
      confirmModalTitle: ''
    };
  },
  methods: {
    onForgotPassword: function(event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        let filter = {
          email: this.email,
          fromDomainType: LoginFromType.CUSTOMER,
          domainWithBasePath: stringUtils.DOMAIN_BASE_PATH,
        };
        this.isLoading = true
        this.SEND_EMAIL_FORGOT_PASSWORD(filter)
          .then(
            function() {
              this.isLoading = false
              this.$toast.open({
                message: this.$t("forgotPassword.pleaseCheckEmailToChangePassword"),
                type: "success",
                ...this.$toastConfig,
              });
              this.email = ''
            }.bind(this)
          )
          .catch(
            function(error) {
              this.isLoading = false
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.$toast.open({
                  message: this.$t(errorText),
                  type: "error",
                  ...this.$toastConfig,
                });
              } else {
                this.$toast.open({
                  message: this.$t("common.somethingWentWrong"),
                  type: "error",
                  ...this.$toastConfig,
                });
              }
            }.bind(this)
          );
      }
    },
    /**
     * Go to login
     */
    onGoToLogin: function() {
      this.$router.push({
        name: "Login",
      });
    },
    ...mapActions(["SEND_EMAIL_FORGOT_PASSWORD", "LOGIN_WITH_GOOGLE"]),
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  height: 34px;
}

.btn-social>:first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  height: 100%;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}

.btn-google {
  color: #fff;
  border-color: rgba(0,0,0,0.2);
  background: rgb(66, 133, 244);
}
</style>
